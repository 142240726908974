import { render, staticRenderFns } from "./wxMiniProgram.vue?vue&type=template&id=06c4f827&scoped=true&"
import script from "./wxMiniProgram.vue?vue&type=script&lang=ts&"
export * from "./wxMiniProgram.vue?vue&type=script&lang=ts&"
import style0 from "./wxMiniProgram.vue?vue&type=style&index=0&id=06c4f827&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06c4f827",
  null
  
)

export default component.exports