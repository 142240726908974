
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import VueTypedJs from "src/views/components/vueTypedJs";
import { Fun } from "src/views/utils/common";
import eventBus from 'src/views/utils/bus';
import { Toast } from "vant";

@Component({
    components:
    {
        VueTypedJs
    }
})
export default class PeopleList extends Vue
{
    /**
     * 最大按钮数量
     * @private
     */
     private maxNum: number = 2;

    /**
     * 性别映射
     * @private
     */
    private sexMap: any = {
        women: 0,
        men: 1,
    };

    /**
     * 数据库状态映射
     * @private
     */
    private statusMap: any = {
        noAppointed: -1,  // 未预约过
        cancel: 0,  // 已取消
        toPay: 1,   // 待付款
        booked: 2,  // 已预约(未超额)
        payed: 3,   // 已预约(已超额)
        completed: 4,  // 已完成
        reportUpload: 5,   // 报告已上传
        refunding: 6,  // 退款中
        refunded: 7,   // 已退款
        expired: 8,  // 已失效
        booking: 77,   // 预约中
        changing: 88,  // 改约中
        canceling: 99,   // 取消中
    };

    /**
     * 页面状态映射
     * @private
     */
    private statusMapView: any = {
        unAppointed: [this.statusMap.cancel, this.statusMap.refunded, this.statusMap.noAppointed], // 待预约
        unCheckup: [this.statusMap.booked, this.statusMap.payed],   // 待体检
        checkuped: [this.statusMap.completed, this.statusMap.reportUpload],   // 体检完成
        waitingPay: [this.statusMap.toPay],  // 预约中待支付
        refunding: [this.statusMap.refunding, this.statusMap.canceling],   // 退款中
        appointing: [this.statusMap.booking, this.statusMap.changing], // 预约中
    };

    /**
     * 状态tag映射
     * @private
     */
    public statusStyleMap: any = [
        {
            statusArr: this.statusMapView.unAppointed,
            statusName: '待预约',
            desc: 'unAppointed',
            background: '#F9F1DF',
            color: '#FF8A00'
        },
        {
            statusArr: this.statusMapView.unCheckup,
            statusName: '待体检',
            desc: 'unCheckup',
            background: '#F9F1DF',
            color: '#FF8A00'
        },
        {
            statusArr: this.statusMapView.checkuped,
            statusName: '体检完成',
            desc: 'checkuped',
            background: '#E0F2E8',
            color: '#00C370'
        },
        {
            statusArr: this.statusMapView.waitingPay,
            statusName: '预约中待支付',
            desc: 'waitingPay',
            background: '#E1ECF9',
            color: '#2A78FF'
        },
        {
            statusArr: this.statusMapView.refunding,
            statusName: '退款中',
            desc: 'refunding',
            background: '#F9E9E9',
            color: '#FF4444'
        },
        ,
        {
            statusArr: this.statusMapView.appointing,
            statusName: '预约中',
            desc: 'appointing',
            background: '#F9E9E9',
            color: '#FF4444'
        },
    ];

    /**
     * 选中数据
     * @private
     */
     private activeId: any = null;

    /**
     * 数据源
     * @private
     * @returns boolean
     */
     private dataList: Array<any> = [];

     /**
     * 数据
     * @private
     * @returns string
     */
     @Prop({type: Object, default: {title: "", data: []}})
    private dataSource: any;

    /**
     * 渲染数据
     * @private
     * @returns string
     */
     private get viewDataList(): Array<any>
    {
        return this.dataSource.data.slice(0, this.maxNum);
    }

    /**
     * 是否显示
     * @private
     * @returns boolean
     */
     private isShow: boolean = false;

    /**
     * 监听弹框变化
     * @private
     * @returns void
     */
    @Watch("isShow")
    private onPopupChange(value: boolean): void
    {
        if(value) {
            eventBus.$emit("changeShowFooter", false);
        }
        else {
            eventBus.$emit("changeShowFooter", true);
        }
    }

    /**
     * 关闭
     * @private
     */
    private onCancel () {
        this.isShow = false;
    }
    
    /**
     * 文字输入完成
     * @private
     */
    private onCompleteTxt()
    {
        // 滚动到最底部
        this.$emit('scollAnimation');
        Fun.addTimeData(this.dataList, this.dataSource.data, this.onKeepTyping)

    }

    // 文字持续输入
    private onKeepTyping()
    {
        // 滚动到最底部
        this.$emit('scollAnimation');
    }
    
    /**
     * 输入框移入事件
     * @private
     * @returns void
     */
    private onMessageInput()
    {
        window.scrollTo(0, document.body.scrollHeight);
    }

    /**
     * 选中
     * @private
     */
    private selectPerson(data)
    {
        this.activeId = data.id;
    }

    /**
     * 确定
     * @private
     */
    private confirmHandle()
    {
        let data = this.dataSource.data.find(item => +item.id === +this.activeId);
        if (!data) {
            Toast('请选择一个员工');
            return;
        }
        let { callFunc, callFuncData, name, department, staffCode, phone, cardNo, cardType } = data;
        let str = `${department || ''}${name || ''}${staffCode && ('<br />工号：' + staffCode)}${phone && ('<br />手机号码：' + phone)}${cardNo && ('<br />' + cardType + '：' + cardNo)}`;
        this.$emit("sendMessage", str);
        this.$emit("toDo", {callFunc, callFuncData});
        this.isShow = false;
    }

    /**
     * 更多
     * @private
     */
    private onToDoMore()
    {
        this.isShow = true;
    }
    
}
