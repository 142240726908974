
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import eventBus from 'src/views/utils/bus';

@Component
export default class PackageComparingResult extends Vue
{

    /**
     * 是否显示
     * @private
     * @returns boolean
     */
    private isShow: boolean = true;

    /**
     * 组件创建钩子
     * @private
     * @returns string
     */
    private created()
    {
        eventBus.$emit("changeShowFooter", false);
    }

    /**
     * 数据
     * @private
     * @returns string
     */
    @Prop({type: Object, default: {title: "", data: {wholeComparing: [], differenceComparing: []}, button: { buttonText: ""}}})
    private dataSource: any;

    /**
     * 关闭
     * @private
     */
    private onCancel () {
        eventBus.$emit("changeShowFooter", true);
        this.isShow = false;
    }

    /**
     * 数据
     * @private
     */
    private state: any = 
    {
        active: 0,
        uuid: "",
        supplierId: null,
        dataSource: []
    };

    /**
     * 提交
     * @private
     */
    private onSubmit()
    {
        this.onCancel();
        this.$emit("closeAll");
    }

    private blurDate: number = null;
    private focusDate: number = null;

    /**
     * 输入框失去焦点
     * @private
     */
    private onBlurInput(indexC: number, index: number, key: string)
    {
        this.blurDate = new Date().getTime();
        setTimeout(() => {
            if (this.focusDate < this.blurDate)
            {
                window.scroll(0,0);
            }
        }, 500);
        let price = this.state.dataSource[indexC].supportStates[index][key];
        if (price && (isNaN(parseInt(price,10)) || /[a-zA-Z]+/.test(price)))
        {
            this.state.dataSource[indexC].supportStates[index][key] = 0;
        }
        else
        {
            this.state.dataSource[indexC].supportStates[index][key] = parseFloat(price).toFixed(2);
        }
    }
    
    /**
     * 执行方法
     * @private
     */
    private onToDo({callFunc, callFuncData})
    {
        this.onCancel();
        this.$emit("toDo", {callFunc, callFuncData});
    }
    
}
