
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Toast } from "vant";
import VueTypedJs from "src/views/components/vueTypedJs";

@Component({
    components:
    {
        VueTypedJs
    }
})
export default class DataPanel extends Vue {
    /**
     * 页面数据
     * @private
     */
    @Prop({type: Object, default: {}})
    private dataSource: any;

    /**
     * 页面数据
     * @desc 删除html字符串中长空格和多余字符
     * @private
     */
    private get viewData() {
        return this.dataSource?.richText.replaceAll(/\s+/g, ' ').replaceAll(/\r\n/g, '');
    };

    /**
     * 复制文本
     * @private
     */
    private async copyData() {
        let data: string = this.dataSource.text || '';
        this.copyText(data);
    };

    /**
     * 复制文本工具方法
     * @private
     */
    private copyText(text) {
        const textString = text.toString(); // 数字没有 .length 不能执行selectText 需要转化成字符串
        let input: any = document.querySelector('#copy-input');
        if (!input) {
            input = document.createElement('textarea');
            input.id = 'copy-input';
            input.readOnly = 'readOnly'; // 防止ios聚焦触发键盘事件
            input.style.position = 'absolute';
            input.style.left = '-2000px';
            input.style.zIndex = '-2000';
            document.body.appendChild(input);
        }
        input.value = textString;
        // ios必须先选中文字且不支持 input.select();
        this.selectText(input, 0, textString.length);
        if (document.execCommand('copy')) {
            document.execCommand('copy');
            Toast('复制成功！');
        } else {
            Toast('复制失败！');
        }
        input.blur();
    }
    /**
     * 复制文本工具方法
     * @private
     */
    private selectText(textbox, startIndex, stopIndex) {
        // 选择文本。createTextRange(setSelectionRange)是input方法
        if (textbox.createTextRange) {
            // ie
            const range = textbox.createTextRange();
            range.collapse(true);
            range.moveStart('character', startIndex); // 起始光标
            range.moveEnd('character', stopIndex - startIndex); // 结束光标
            range.select(); // 不兼容苹果
        } else {
            // firefox/chrome
            textbox.setSelectionRange(startIndex, stopIndex);
            textbox.focus();
        }
    }

    /**
     * 文字输入完成
     * @private
     */
     private onCompleteTxt()
    {
        // 滚动到最底部
        this.$emit('scollAnimation');

    }

    // 文字持续输入
    private onKeepTyping()
    {
        // 滚动到最底部
        this.$emit('scollAnimation');
    }
}
