
import { Component, Vue } from "vue-property-decorator";
import { OrganizationService, PackageService, WechatService } from "src/services";
import { Toast } from "vant";
import { ORG_TYPE } from "src/enums";
import globalConfig from "src/config/index";
import dayjs from "dayjs";
import AiScheduling from "src/views/components/common/scheduling";
import eventBus from 'src/views/utils/bus';
import { tools } from "src/utils";

@Component({
    components:
    {
        AiScheduling
    }
})
export default class OrgList extends Vue
{
    /**
     * 品牌列表
     * @private
     * @returns Array<any>
     */
    private hospBrandList: Array<any> = [];

    /**
     * 日期加载中
     * @private
     * @returns boolean
     */
    private scheduleLoading: boolean = false;

    /**
     * 是否在加载中
     * @private
     * @returns boolean
     */
    private isLoading: boolean = false;

    /**
     * 机构类型
     * @private
     * @returns ORG_TYPE
     */
    private ORG_TYPE: ORG_TYPE;

    /**
     * 是否选择城市弹框
     * @private
     * @returns boolean
     */
    private showCity: boolean = false;

    /**
     * 排期列表
     * @private
     * @returns  Array<any>
     */
    private schedulesDataList: Array<any> = [];

    /**
     * 数据列表
     * @private
     * @returns Array<any>
     */
    private dataList: Array<any> = [];

    private allDatalist: Array<any> = [];

    /**
     * 搜索关键字
     * @private
     * @returns string
     */
    private filters: any =
    {
        key: "",
        packageId: "",
        longitude: "",
        latitude: "",
        brandType: "",
        cityName:"深圳市",
    };

    /**
     * 是否正在加载中
     * @private
     * @returns boolean
     */
    private loading: boolean = false;

    /**
     * 套餐id
     * @private
     * @returns string
     */
    private get packageId(): string
    {
        return this.$route.query && (this.$route.query.id as string) || "";
    }

    /**
     * employerMemberId
     * @private
     * @returns string
     */
    private get employerMemberId(): string
    {
        return this.$route.query && (this.$route.query.employerMemberId as string) || "";
    }

    /**
     * 当前位置
     * @private
     * @returns any
     */
    private get location(): any
    {
        return this.$store.getters.location;
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
    protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created(): void
    {
        this.filters.packageId = this.packageId;
        if(this.location)
        {
            this.filters.cityName = this.location.city;
            this.filters.latitude = this.location.lat;
            this.filters.longitude = this.location.lng;
        }

        // 获取机构列表
        this.getOrgList(this.filters);
        
    }

    /*
     * 显示城市组件弹框
     * @private
     * @returns void
     */
    private onShowCityModel(): void
    {
        this.showCity = true;
    }

    /*
     * 跳转详情
     * @private
     * @returns void
     */
    private onDetail(id: string,item): void
    {
        let query : any = {id: this.packageId, hospitalId: id};
        this.$router.push({name: "package-detail", query: query});
    }

    /**
     * 跳转页面
     * @private
     * @returns void
     */
    private onGopage(name: string): void
    {
        this.$router.push({name});
    }

    /*
     * 选择城市
     * @private
     * @returns void
     */
    private onSelectCity(city: string): void
    {
        this.filters.cityName = city;
        this.filters.brandType = "";
        this.getOrgList();
        this.showCity = false;
    }

    /**
     * 获取数据
     * @private
     * @returns void
     */
    private async getOrgList(params: any = {}): Promise<void>
    {
        this.isLoading = true;

        let filters = Object.assign(this.filters, params);
        try
        {
            let {content: result} =  await OrganizationService.instance.getHospitalList(filters);
            if(result.data)
            {
                this.allDatalist = result.data.hospitals;
                this.dataList = JSON.parse(JSON.stringify(result.data.hospitals));
                this.hospBrandList = result.data.brands;
                this.loading = false;
            }

            let ids = this.allDatalist.map((items)=> {
                return items.id
            })
            if(ids && ids.length>0)
            {   
                this.getSupportHospitalSchedules(ids);
            }
        }
        catch(err)
        {
            Toast(err);
        }
        finally
        {
            this.isLoading = false;
        }
    }

    /**
     * 获取机构列表排期
     * @private
     * @returns void
     */
    private async getSupportHospitalSchedules(hospitalIds): Promise<void>
    {
        this.allDatalist.forEach(res => res.schedules = []);
        this.dataList.forEach(res => res.schedules = []);
        try
        {
            this.scheduleLoading = true;
            let {content: result} =  await OrganizationService.instance.getSupportHospitalSchedules({
                packageId: this.filters.packageId,
                hospitalIds: hospitalIds
            });
            this.scheduleLoading = false;
            if(result.data)
            {
                this.allDatalist && this.allDatalist.forEach(res => {
                    let row = result.data.filter(item => item.hospitalId == res.id);
                    res.schedules = row && row[0].schedules;
                })
                this.dataList && this.dataList.forEach(res => {
                    let row = result.data.filter(item => item.hospitalId == res.id);
                    res.schedules = row && row[0].schedules;
                })
            }
        }
        catch(err)
        {
            Toast(err);
        }
      
    }
    
    /**
     * 触发搜索城市事件
     * @private
     * @returns string
     */
    private onSearchCity(val): void
    {
        this.filters.key = val;
        this.onSearch();
    }

    /**
     * 切换tab
     * @private
     * @param {number} number
     * @returns void
     */
     private onTabChange(id: number | string): void
    {
        this.filters.brandType = id;
        this.onSearch();
    }

    /**
     * 搜索
     * @private
     * @param {number} number
     * @returns void
     */
    private onSearch(): void
    {
        this.dataList = this.allDatalist;
        if(this.filters.key)
        {
            this.dataList = this.dataList.filter(item=>item.name.indexOf(this.filters.key)>= 0);
        }

        if(this.filters.brandType!=="")
        {
            this.dataList = this.dataList.filter(item=>item.brandType === this.filters.brandType);
            
        }
    }

    /**
     * 取消搜索城市事件
     * @private
     * @returns void
     */
    private onClearCityRseult(): void
    {
        this.filters.key = "";
        this.onSearch();
    }

    /**
     * 是否显示日期
     * @private
     * @returns 
     */
    private showDate: boolean = false;

    /**
     * submitData
     * @private
     * @returns 
     */
    private submitData: any = {
        hospitalId: null,
        packageId: 0,
        selectDate: ""  
    };

    /**
     * 显示日期弹框
     * @private
     * @returns void
     */
    private showDateModal(id: number, date: string = ""): void
    {
        this.submitData.hospitalId = id;
        this.submitData.packageId = this.packageId;
        this.submitData.selectDate = date ? dayjs(date).format("YYYY-MM-DD") : "";
        this.showDate = true;
    }

    /**
     * 确认日期
     * @private
     * @returns void
     */
    private onDateConfirm(date: any, period: any): void
    {
        if (!date)
        {
            Toast("必须选择一个日期");
            return;
        }
        this.submitData.selectDate = dayjs(date).format("YYYY-MM-DD");
        this.submitData.physicalTimePeriod = period;
        this.showDate = false;
        this.onNext();
    }

    /**
     * 获取套餐详情
     * @private
     * @returns void
     */
     private async onNext(): Promise<void>
    {

        try
        {
            let {content: result} =  await PackageService.instance.getPackageInfo(this.submitData);

            if(result.data)
            {
                if (result.data.hasOptionalItems)
                {
                    // 加项页面
                    this.$router.push({name: "ai-package-optional", 
                                    query:{
                                        id: this.submitData.packageId, 
                                        date: this.submitData.selectDate, 
                                        physicalTimePeriod: this.submitData.physicalTimePeriod, 
                                        hospitalId: this.submitData.hospitalId,
                                        employerMemberId: this.employerMemberId,
                                        routerGo: "2"
                                    }
                                });
                }
                else
                {
                     // 直接下单
                    eventBus.$emit('onDo', {callFunc: "ToBookOrder", callFuncData: {
                        employerMemberId: +this.employerMemberId,
                        packageId: +this.submitData.packageId,
                        hospitalId: +this.submitData.hospitalId,
                        physicalTime: this.submitData.selectDate,
                        physicalTimePeriod: this.submitData.physicalTimePeriod,
                        hasOptionalItems: false
                    }});
                    this.$router.go(-1);
                }
            }

        }
        catch(err)
        {
            Toast(err);
        }
    }

    

    /**
     * 跳转地图
     * @private
     * @returns void
     */
     private async openMap(hospitalInfo: any): Promise<any>
    {
        if(hospitalInfo)
        {
            let lat = hospitalInfo.latitude;
            let lng =hospitalInfo.longitude;;
            let address = hospitalInfo.address;
            let orgName = hospitalInfo.name;

            if(tools.isWechat())
            {
                let currentUrl = location.href;
                let {content: result} = await WechatService.instance.getWechatJSSDK(currentUrl);
                if(result.data)
                {
                    wx.config({
                        debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: result.data.appId, // 必填，公众号的唯一标识
                        timestamp: result.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
                        signature: result.data.signature, // 必填，签名
                        jsApiList: ["openLocation"] // 必填，需要使用的JS接口列表
                    });

                    wx.ready(function()
                    {
                        wx.openLocation({
                            latitude: parseFloat(lat), // 纬度，范围为-90~90，负数表示南纬
                            longitude: parseFloat(lng), // 经度，范围为-180~180，负数表示西经
                            scale: 14, // 缩放比例
                            name: orgName,
                            address: address, // 地图缩放级别,整形值,范围从1~28。默认为最大
                            infoUrl: ""
                        });
                    });
                }
            }
            else
            {
                // 腾讯地图
                // let href = `http://apis.map.qq.com/uri/v1/marker?marker=coord:${lat},${lng};addr:${this.orgDetailInfo.org_name}`;
                // 高德
                let href = `http://uri.amap.com/marker?position=${lng},${lat}&name=${orgName}&coordinate=gaode&callnative=1`;
                window.open(href);
            }

        }

    }
}
