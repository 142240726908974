
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import VueTypedJs from "src/views/components/vueTypedJs";
import { Fun } from "src/views/utils/common";
import eventBus from 'src/views/utils/bus';

@Component({
    components:
    {
        VueTypedJs
    }
})
export default class PeopleList extends Vue
{
    /**
     * 性别映射
     * @private
     */
    private sexMap: any = {
        women: 0,
        men: 1,
    };

    /**
     * 数据库状态映射
     * @private
     */
    private statusMap: any = {
        noAppointed: -1,  // 未预约过
        cancel: 0,  // 已取消
        toPay: 1,   // 待付款
        booked: 2,  // 已预约(未超额)
        payed: 3,   // 已预约(已超额)
        completed: 4,  // 已完成
        reportUpload: 5,   // 报告已上传
        refunding: 6,  // 退款中
        refunded: 7,   // 已退款
        expired: 8,  // 已失效
        booking: 77,   // 预约中
        changing: 88,  // 改约中
        canceling: 99,   // 取消中
    };

    /**
     * 页面状态映射
     * @private
     */
     private statusMapView: any = {
        unAppointed: [this.statusMap.cancel, this.statusMap.refunded, this.statusMap.noAppointed], // 待预约
        unCheckup: [this.statusMap.booked, this.statusMap.payed],   // 待体检
        checkuped: [this.statusMap.completed, this.statusMap.reportUpload],   // 体检完成
        waitingPay: [this.statusMap.toPay],  // 预约中待支付
        refunding: [this.statusMap.refunding, this.statusMap.canceling],   // 退款中
        appointing: [this.statusMap.booking, this.statusMap.changing], // 预约中
    };

    /**
     * 状态tag映射
     * @public
     */
     public statusStyleMap: any = [
        {
            statusArr: this.statusMapView.unAppointed,
            statusName: '待预约',
            desc: 'unAppointed',
            background: '#F9F1DF',
            color: '#FF8A00'
        },
        {
            statusArr: this.statusMapView.unCheckup,
            statusName: '待体检',
            desc: 'unCheckup',
            background: '#F9F1DF',
            color: '#FF8A00'
        },
        {
            statusArr: this.statusMapView.checkuped,
            statusName: '体检完成',
            desc: 'checkuped',
            background: '#E0F2E8',
            color: '#00C370'
        },
        {
            statusArr: this.statusMapView.waitingPay,
            statusName: '预约中待支付',
            desc: 'waitingPay',
            background: '#E1ECF9',
            color: '#2A78FF'
        },
        {
            statusArr: this.statusMapView.refunding,
            statusName: '退款中',
            desc: 'refunding',
            background: '#F9E9E9',
            color: '#FF4444'
        },
        ,
        {
            statusArr: this.statusMapView.appointing,
            statusName: '预约中',
            desc: 'appointing',
            background: '#E1ECF9',
            color: '#2A78FF'
        },
     ];

     /**
     * 数据
     * @private
     * @returns string
     */
     @Prop({type: Object, default: {title: "", data: []}})
    private dataSource: any;

    /**
     * 是否显示体检额度
     * @desc 待预约/待支付状态展示
     * @private
     * @returns boolean
     */
    private get showQuotaPrice() {
        return this.statusMapView.unAppointed.includes(this.viewData.status) || this.statusMapView.waitingPay.includes(this.viewData.status);
    }

    /**
     * 是否显示体检额度
     * @desc 无hospital字段或待支付状态
     * @private
     * @returns boolean
     */
     private get viewData() {
        return this.dataSource.data;
     }
    
    /**
     * 文字输入完成
     * @private
     */
    private onCompleteTxt()
    {
        // 滚动到最底部
        this.$emit('scollAnimation');
    }

    // 文字持续输入
    private onKeepTyping()
    {
        // 滚动到最底部
        this.$emit('scollAnimation');
    }
    
    /**
     * 跳转订单列表
     * @private
     * @returns void
     */
    private goOrderList(): any
    {
        let routeName: string = 'order-list';
        this.$router.push({name: routeName, query: {isAi: "true", employerMemberId: this.viewData.id}});
    }
    /**
     * 跳转订单详情
     * @private
     * @returns void
     */
    private goOrderDetail(): any
    {
        let id: string = this.viewData.orderId || '';
        // 待预约状态不跳转
        if (this.statusMapView.unAppointed.includes(this.viewData.status)) return;
        this.$router.push({name:"orderDetail", params:{id},query:{isAi: "true" + ""}})
    }
}
