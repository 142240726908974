class Fun
{
    /**
     * 数据递增
     * @private
     * @param key 参数名
     * @param value 赋值数据    
     * @param time 时间
     * @param callFun 执行方法
     */
    public static addTimeData(obj:any, value: Array<any>, callFun: any, time: number = 100, lastFun: any = null)
    {
        if (!obj || !value || value.length === 0)
        {
            return;
        }
        let num = 0;
        let timer = null;
        obj.push(value[num]);
        num++;
        callFun && callFun();
        timer = window.setInterval(() => {
            if (num < value.length)
            {
                obj.push(value[num]);
                num++;
                callFun && callFun();
            }
            else
            {
                window.clearInterval(timer);
                callFun && callFun();
                lastFun && lastFun();
            }
        }, time)
    }
}

export default Fun;