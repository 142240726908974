
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Toast } from "vant";
import preview from "./preview.vue";
import eventBus from 'src/views/utils/bus';

@Component({
    components:
    {
        preview
    }
})
export default class DownloadFile extends Vue {
    /**
     * 页面数据
     * @private
     */
    @Prop({type: Object, default: {}})
    private dataSource: any;

    private async downloadFile() {
        this.emailPop.show = true;
    }

    /**
     * 监听弹框变化
     * @private
     * @returns void
     */
     @Watch("emailPop.show")
    private onPopupChange(value: boolean): void
    {
        if(value) {
            eventBus.$emit("changeShowFooter", false);
        }
        else {
            eventBus.$emit("changeShowFooter", true);
        }
    }

    /**
     * 邮箱填写
     * @private
     */
    private emailPop: any = {
        show: false,
        value: "",
    }

    /**
     * 发送邮箱 - 确认
     * @private
     * @returns void
     */
    private async onSendEmailSubmit()
    {
        let emailRegExp = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        let value = this.emailPop.value;
        if (emailRegExp.test(value)) {
            try {
                let { callFunc, callFuncData } = this.dataSource;
                callFuncData.email = this.emailPop.value;
                this.$emit("sendMessage", this.emailPop.value);
                this.$emit("toDo", { callFunc, callFuncData });
                this.emailPop.show = false;
            }
            catch(err) {
                Toast('提交失败');
            }
        }
        else {
            Toast("输入的邮箱格式不正确");
        }
    }

    /**
     * 输入框移入事件
     * @private
     * @returns void
     */
    private onMessageInput()
    {
        window.scrollTo(0, document.body.scrollHeight);
    }

    /**
     * 预览
     * @private
     * @returns void
     */
    private previewHandle()
    {
        (this.$refs.$preview as any).init({ data: this.dataSource.data || [] });
    }
    
}
