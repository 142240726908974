
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import VueTypedJs from "src/views/components/vueTypedJs";
import eventBus from 'src/views/utils/bus';
import { Fun } from "src/views/utils/common";

@Component({
    components:
    {
        VueTypedJs
    }
})
export default class Reportist extends Vue
{
    
    /**
     * 数据
     * @private
     */
    private dataList: Array<any> = [];

    /**
     * 数据
     * @private
     * @returns string
     */
    @Prop({type: Object, default: {title: "", data: []}})
    private dataSource: any;

    // 文字持续输入
    private onKeepTyping()
    {
        // 滚动到最底部
        this.$emit('scollAnimation');
    }
    
    /**
     * 文字输入完成
     * @private
     */
    private onCompleteTxt()
    {
        // 滚动到最底部
        this.$emit('scollAnimation');
        Fun.addTimeData(this.dataList, this.dataSource.data, this.onKeepTyping);
    }
    
    /**
     * 跳转订单列表
     * @private
     * @returns string
     */
     private onPageTo(name: string): any
    {
        this.$router.push({name, query: {isAi: "true"}})
    }

    /**
     * 跳转详情
     * @private
     * @returns string
     */
    private onDetail(item): any
    {
        this.$router.push({name:"orderDetail", params:{id:item.id}, query:{reportId:item.reportId,isRead:item.isRead, isAi: "true"}})
    }
    
}
