
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class SearchPeople extends Vue
{
    /**
     * 页面数据
     * @private
     */
     @Prop({type: Object, default: {}})
    private dataSource: any;

    /**
     * 员工姓名
     * @private
     * @returns string
     */
     private searchName: string = '';

    /**
     * 是否已查找
     * @private
     * @returns boolean
     */
    private isSearched: boolean = false;

    /**
     * 是否置灰按钮
     * @des 未填写或未更改，不可点击
     * @returns boolean
     */
    private get isDisabled() {
        return !this.searchName || this.searchName === this.dataSource?.callFuncData?.name;
    }

    /**
     * 提交
     * @private
     */
    private onSubmit() {
        let { callFunc, callFuncData = {} } = this.dataSource;
        callFuncData.name = this.searchName;
        this.$emit("sendMessage", `姓名：${this.searchName}`);
        this.$emit("toDo", { callFunc, callFuncData });
        this.isSearched = true;
    }

     /**
     * 输入框移入事件
     * @private
     * @returns void
     */
    private onMessageInput()
    {
        window.scrollTo(0, document.body.scrollHeight);
    }

    /**
     * 组件创建钩子
     * @protected
     * @returns 
     */
    protected mounted() {
        this.searchName = this.dataSource?.callFuncData?.name || '';
    }

}
