
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import eventBus from 'src/views/utils/bus';


@Component
export default class Picker extends Vue
{

    /**
     * 是否显示
     * @public
     * @returns boolean
     */
    public showPickerPopup: boolean = false;

    /**
     * 监听弹框变化
     * @private
     * @returns void
     */
     @Watch("showPickerPopup")
    private onPopupChange(value: boolean): void
    {
        if(value) {
            eventBus.$emit("changeShowFooter", false);
        }
        else {
            eventBus.$emit("changeShowFooter", true);
        }
    }
    /**
     * 数据
     * @private
     * @returns array
     */
     @Prop({type: Array, default: []})
    private data: any;

    /**
     * 选中pop
     * @private
     */
    private onConfirm(item: any) {
        this.showPickerPopup = false;
        this.$emit("onConfirm", item);
    }

    /**
     * 关闭pop
     * @private
     */
    private onCancel() {
        this.showPickerPopup = false;
        this.$emit("onCancel");
    }
}
