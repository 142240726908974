
import { Component, Vue, Prop } from "vue-property-decorator";
import { Toast } from "vant";
import { UserService } from "src/services";
import privacy from "src/views/privacy.vue";
import agreement from "src/views/agreement.vue";
import eventBus from 'src/views/utils/bus';

@Component({
    components:
    {
        agreement,
        privacy,
    }
})
export default class LoginPop extends Vue
{

    /**
     * 是否显示
     * @private
     * @returns string
     */
     private showLoginPopup: boolean = true;

    /**
     * state
     * @private
     */
    private state: any = {showPwd: false, isRememberPassword:false};

    /**
     * 定时器
     * @private
     */
    private timerInterval: any = null;

    /**
     * formData
     * @private
     */
    private formData: any = {
        loginType: 1,
        checked: false
    };

    /**
     * 登录类型应映射
     * @private
     * @returns any
     */
     private loginTypeMap: any = {
        account: 1,
        phone: 2,
     };

    /**
     * 登录类型
     * @private
     * @returns any
     */
    private loginTypes: Array<any> = [
        {
            loginType: this.loginTypeMap.account,
            loginTypeStr: '账号登录'
        },
        {
            loginType: this.loginTypeMap.phone,
            loginTypeStr: '手机号登录'
        },
    ];

    /**
     * 账号登录
     * @protected
     * @returns boolean
     */
    private accountModel: any = {
        loginType: 1,
        loginKey: "",
        loginVal: "",
        module: 3   // 3代表企业端账号登录
    };

    /**
     * 手机验证码登录
     * @protected
     * @returns boolean
     */
    private accountModelTel: any = {
        loginType: 2,
        loginKey: "",
        loginVal: "",
        module: 3   // 3代表企业端账号登录
    };

    /**
     * 是否显示服务协议
     * @private
     * @returns boolean
     */
    private showAgreement: boolean = false;

    /**
     * 是否显示隐私协议
     * @private
     * @returns boolean
     */
    private showPrivacy: boolean = false;

    /**
     * 数据
     * @private
     * @returns string
     */
     @Prop({type: Object, default: {triggerMethod: "", triggerData: null}})
    private dataSource: any;

    /* 改变登录方式
    * @private
    * @returns void
    */
    private onChangeTab(type): void {
        this.formData.loginType = type;
    };

    /**
     * 倒计时
     * @protected
     * @returns boolean
     */
    private timerValue: number = 300;

    /**
     * 获取验证码
     * @protected
     * @returns 
     */
     private onGetCode (): void
    {
        if (this.timerValue == 300)
        {
            let regex = /^1[3456789]\d{9}$/;
            if (this.accountModelTel.loginKey.trim() === '') {
                Toast('请输入您的手机号码！');
                return;
            } else if (!regex.test(this.accountModelTel.loginKey)) {
                Toast("请输入正确的手机号码！");
                return;
            }
            this.smsSend();
            this.timerValue--;
            this.timerInterval = setInterval(this.timer, 1000);
        }
    }

    /**
     * 请求状态
     * @protected
     * @returns boolean
     */
    private loading : boolean = false;

    /**
     * 短信发送
     * @private
     * @return void
     */
     private async smsSend(): Promise<void>
    {
        try
        {           
            let json = {smsType: 1, phone: this.accountModelTel.loginKey};
            await UserService.instance.smsSend(json);
            Toast("验证码已发送，请注意查收！");
        }
        catch(err)
        {
            Toast(err);
            this.timerValue = 300;
            clearInterval(this.timerInterval);
        }
        
    }

    /**
     * 验证码-计时器
     * @private
     * @returns 
     */
    private timer (): void
    {
        if (this.timerValue > 1)
        {
            this.timerValue --;
        }
        else
        {
            this.timerValue = 300;
            clearInterval(this.timerInterval);
        }
        
    }
    /**
     * 组件创建钩子
     * @protected
     * @returns 
     */
    protected mounted() {
        let loginDataStr= localStorage.getItem("loginData");
        if(loginDataStr)
        {
            this.state.isRememberPassword = true;
            let data = JSON.parse(loginDataStr);
            this.accountModel.loginType = 1;
            this.accountModel.loginKey = data && data.loginKey;
            this.accountModel.loginVal = data && data.loginVal;
            this.accountModel.module = 3;
        }
    }

    /**
     * 登录
     * @private
     * @return void
     */
    private async onHandleSubmit(): Promise<void>
    {
        try
        {           
            if(!this.formData.checked)
            {
                Toast("请先勾选服务协议");
                return;
            }
            if(this.loading){
                return;
            };     
            // 帐号登入情况
            if(this.formData.loginType == 1)
            {
                if(this.state.isRememberPassword)
                {
                    let loginData = JSON.stringify(this.accountModel);
                    localStorage.setItem("loginData", loginData)
                }
                else
                {
                    localStorage.removeItem("loginData")
                }
            }
            
            this.loading = true;
            let json = this.formData.loginType == 1 ? this.accountModel : this.accountModelTel;
            // 用户登录
            let {content: result} = await UserService.instance.sysLogin(Object.assign({}, {mustBePerfect: true}, json));
            if(result.data)
            {
                this.$store.dispatch("setUserInfo", result.data);
                Toast("登录成功！");
                this.$nextTick(()=>
                {
                    this.$emit('toDo', {callFunc: "AfterLoginHandle", callFuncData: this.dataSource});
                    this.showLoginPopup = false;
                })
            }
            this.loading = false;
        }
        catch(err)
        {
            this.loading = false;
            Toast(err)
        }
    }

    /* 跳转隐私协议
    * @private
    * @returns void
    */
    private onPrivacy(): void
    {
        this.showPrivacy = true;
    }

    /* 关闭隐私协议
    * @private
    * @returns void
    */
    private closePrivacy(): void
    {
        this.showPrivacy = false;
    }

    /* 跳转用户协议
    * @private
    * @returns void
    */
    private onAgreement(): void
    {
        this.showAgreement = true;
    }

    /* 关闭用户协议
    * @private
    * @returns void
    */
    private closeAgreement(): void
    {
        this.showAgreement = false;
    }
    
    /**
     * 输入框移入事件
     * @private
     * @returns void
     */
    private onMessageInput()
    {
        window.scrollTo(0, document.body.scrollHeight);
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created(): void
    {
        eventBus.$emit("changeShowFooter", false);
        localStorage.removeItem("openid");
    }

    /**
     * 关闭
     * @private
     * @returns void
     */
    private onClose(): void
    {
        eventBus.$emit("changeShowFooter", true);
    }
    
}
