
import { Component, Vue } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import html2canvas from 'html2canvas';
import { ImagePreview } from 'vant';

@Component
export default class EditQuotePop extends Vue
{

    /**
     * 数据
     * @private
     */
    private dataList: Array<any> = [];


    /**
     * 图片宽高
     * @private
     */
    private style: any = 
    {
        height: 0,
        width: 0
    };


    /**
     * 是否显示
     * @private
     */
    private show: boolean = false;

    /**
     * 图片值
     * @private
     */
    private dataURL: string = "";

    /**
     * 初始化数据
     * @private
     */
    private init(params: any)
    {
        console.log('params', params)
        let {data} = params;
        this.dataURL = "";
        this.dataList = data;
        this.$nextTick(() => {
            let dom = document.getElementById("inquirySheet-content");
            if (!dom)
            {
                return;
            }
            html2canvas(dom, {
                // 转换为图片
                useCORS: true, // 解决资源跨域问题
                scale: 1, // 处理模糊问题
                //dpi: 300, // 处理模糊问题
                // width:document.documentElement.clientWidth,
                height: dom.scrollHeight,
                width: dom.scrollWidth,
                windowHeight: dom.scrollHeight
            }).then((canvas) => {
                this.style = 
                {
                    height: dom.scrollHeight * (document.body.clientWidth / dom.scrollWidth),
                    width: document.body.clientWidth,
                    transform: `scale(${document.body.clientWidth / dom.scrollWidth})`
                }
                this.dataURL = canvas.toDataURL('image/jpeg', 1.0);
                this.show = true;
                this.onImagePreview(this.dataURL);
                this.$emit("success");
            });
        })
    }
 
    /**
     * 获取供应商报价
     * @return Promise<void>
     */
    private getTotalPrice = (dataList, supplierId: any) =>
    {
        let  femaleMarrTotalPrice = 0;
        let  femaleUnMarrTotalPrice = 0;
        let  maleTotalPrice = 0;
        dataList.forEach(data=>
        {
            let supplier =  data.supportStates.filter(d =>supplierId ==d.supplierId);
            if(supplier && supplier.length > 0)
            {
                let item = supplier[0];
                femaleMarrTotalPrice+=(+item.femaleMarrPrice);
                femaleUnMarrTotalPrice+=(+item.femaleUnMarrPrice);
                maleTotalPrice+=(+item.malePrice);
            }
        })
        
        return {
            femaleMarrTotalPrice: femaleMarrTotalPrice.toFixed(2),
            femaleUnMarrTotalPrice: femaleUnMarrTotalPrice.toFixed(2),
            maleTotalPrice: maleTotalPrice.toFixed(2),
        }
    }
 
    /**
     * 点击图片放大
     * @return Promise<void>
     */
    private onImagePreview = (imgurl: string) =>
    {
        ImagePreview({images: [imgurl], showIndex: false});
    }
}
