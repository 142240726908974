
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import VueTypedJs from "src/views/components/vueTypedJs"
import { Fun } from "src/views/utils/common";

@Component({
    components:
    {
        VueTypedJs
    }
})
export default class SelectPackage extends Vue
{
    /**
     * 按钮是否已点击
     * @private
     */
     private isBtnClick: boolean = false;
   
    /**
     * 当前选中行id
     * @private
     */
    private curId: number = null;

    /**
     * 是否显示更多
     * @private
     */
    private isExpand: boolean = false;

    /**
     * 数据源
     * @private
     */
     private dataList: Array<any> = [];

    /**
     * 数据
     * @private
     * @returns string
     */
    @Prop({type: Object, default: {title: "", data: [], button: {buttonText: "确认"}}})
    private dataSource: any;

    // 文字持续输入
    private onKeepTyping()
    {
        // 滚动到最底部
        this.$emit('scollAnimation');
    }

    /**
     * 文字输入完成
     * @private
     */
    private onCompleteTxt()
    {
        // 滚动到最底部
        this.$emit('scollAnimation');
        Fun.addTimeData(this.dataList, this.dataSource.data, this.onKeepTyping, 200);
    }

    /**
     * 当前位置
     * @private
     * @returns any
     */
    private get location(): any
    {
        return this.$store.getters.location;
    }

    /**
     * 执行方法
     * @private
     */
    private onToDo({callFunc, callFuncData})
    {
        let curData = this.dataList.filter(res => res.id ==  this.curId);
        if (curData.length <= 0)
        {
            return;
        }
        callFuncData.packageId = curData[0].id;
        callFuncData.packageName = curData[0].name;
        callFuncData.cityName = this.location && this.location.city || "深圳市";
        callFuncData.longitude = this.location && this.location.lng || 114.012325;
        callFuncData.latitude = this.location && this.location.lat || 22.540269;
        this.$emit("toDo", {callFunc, callFuncData});
        this.isBtnClick = true;
    }

    /**
     * 跳转套餐详情
     * @private
     */
    private onPackageTo(packageId: number)
    {
        this.$router.push({name: 'ai-package', query: { id: packageId+ "", employerMemberId: this.dataSource.employerMemberId}});
    }

    /**
     * 改变当前选中套餐行
     * @private
     */
    private onChangeCur(id: number)
    {
        if (this.isBtnClick) return;
        this.curId = id;
    }
    
}
