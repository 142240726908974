
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import VueTypedJs from "src/views/components/vueTypedJs";
import { Fun } from "src/views/utils/common";
import Picker from "src/views/components/common/picker";

@Component({
    components:
    {
        VueTypedJs,
        Picker
    }
})
export default class TitleButton extends Vue
{
    
    /**
     * 按钮数组
     * @private
     */
    private buttons: Array<any> = [];

    /**
     * 按钮数组
     * @private
     */
    private isShowPicker: Boolean = false;

    /**
     * 最大按钮数量
     * @private
     */
    private maxNum: number = 4;

    /**
     * 数据
     * @private
     * @returns string
     */
    @Prop({type: Object, default: {title: "", buttons: []}})
    private dataSource: any;

    /**
     * 渲染数据
     * @private
     * @returns array
     */
     private get viewButtons(): Array<any>
    {
        return this.buttons.slice(0, this.maxNum);
    }

    /**
     * 是否显示更多按钮
     * @private
     * @returns boolean
     */
     private get isShowMore(): Boolean
    {
        return this.buttons.length > this.maxNum;
    }

    // 文字持续输入
    private onKeepTyping()
    {
        // 滚动到最底部
        this.$emit('scollAnimation');
    }
    
    /**
     * 文字输入完成
     * @private
     */
    private onCompleteTxt()
    {
        // 滚动到最底部
        this.$emit('scollAnimation');
        Fun.addTimeData(this.buttons, this.dataSource.buttons, this.onKeepTyping);
    }
    
    /**
     * 执行方法
     * @private
     */
    private onToDo({callFunc, callFuncData, buttonText})
    {
        this.$emit("sendMessage", buttonText || '');
        this.$emit("toDo", {callFunc, callFuncData});
    }

    /**
     * 更多
     * @private
     */
    private onToDoMore({callFunc, callFuncData})
    {
        (this.$refs.picker as any).showPickerPopup = true;
    }

    /**
     * 选中pop
     * @private
     */
    private onConfirm(data)
    {
        this.onToDo(data);
        (this.$refs.picker as any).showPickerPopup = false;
    }

    /**
     * 关闭pop
     * @private
     */
    private onCancel()
    {
        (this.$refs.picker as any).showPickerPopup = false;
    }

}
