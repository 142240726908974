
  import { Component, Vue } from "vue-property-decorator";
  import pageBack from "src/components/page-back";
  import vtable from "src/components/vtable"
  import globalConfig from "src/config/index";
  
  @Component({
      components:{
          pageBack,
          vtable
      }
  })
  export default class Login extends Vue
  {
  
      private data = [ 
          {
            plugin: '高德地图',
            name: '高德软件有限公司',
            type: '精准定位信息',
            scene: '地图定位',
            objective:'位置显示',
            share:'接口传输',
            privacy:'https://lbs.amap.com/pages/privacy/?spm=a1zaa.8161610.0.0.1a1173f7mz8x4A',
            mark:'是',
            frequency:'使用时调用',
          }
      ]
  
      private header = [
          {title: '插件名称', prop: 'plugin', width: '130', align: 'center'},
          {title: '第三方公司名称', prop: 'name', width: '130', align: 'center'},
          {title: '个人信息类型', prop: 'type', width: '130', align: 'center'},
          {title: '使用场景', prop: 'scene', width: '100', align: 'center'},
          {title: '使用目的及功能', prop: 'objective', width: '130', align: 'center'},
          {title: '共享方式', prop: 'share', width: '100', align: 'center'},
          {title: '第三方隐私政策链接', prop: 'privacy', width: '200', align: 'center'},
          {title: '是否去标识化传输', prop: 'mark', width: '130', align: 'center'},
          {title: '频次', prop: 'frequency', width: '100', align: 'center'},
      ]

      /**
       * 返回
       */
       private customFn()
      {
          this.$emit("close-sdk");
      }
  
      /**
       * 获取当前登入企业相关配置
       * @private
       * @returns void
       */
      protected get employerConfig(): any
      {
          let appid = localStorage.getItem("appid");
  
          let employerConfig = globalConfig.employerConfig[appid];
  
          return employerConfig;
      }
  }
  
  
  