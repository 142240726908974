var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('van-popup',{attrs:{"round":"","position":"bottom"},on:{"close":_vm.onCancel},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('div',{staticClass:"addFamily-wrap"},[_c('div',{staticClass:"hea-row header-content"},[_c('div',{staticClass:"cancel"}),_c('div',{staticClass:"hea-flex-item1 title hea-c"},[_vm._v("修改人员信息")]),_c('div',{staticClass:"confirm hea-c",on:{"click":_vm.onCancel}},[_c('van-icon',{attrs:{"name":"cross","color":"#666666"}})],1)]),_c('div',{staticClass:"contacts"},[_c('van-form',{on:{"submit":_vm.onSubmitContacts}},[_c('van-field',{attrs:{"required":"","label":"姓名","input-align":"right","rules":[
                    {required: true, message: '请输入真实姓名',trigger: 'onBlur' },
                ],"error-message-align":"right","placeholder":"请输入真实姓名"},model:{value:(_vm.modelData.memberName),callback:function ($$v) {_vm.$set(_vm.modelData, "memberName", $$v)},expression:"modelData.memberName"}}),(_vm.isSelf)?_c('van-field',{attrs:{"required":"","input-align":"right","error-message-align":"right","label":"手机号","is-link":"","readonly":"","rules":[
                    {required: true, message: '请输入手机号',trigger: 'onBlur' },
                    { validator:_vm.validatorPhone, message: '请输入正确手机号',trigger: 'onBlur' },   
                ],"placeholder":"请填写正确的手机号以保证预约顺畅"},on:{"click":_vm.updatePhone},model:{value:(_vm.modelData.phone),callback:function ($$v) {_vm.$set(_vm.modelData, "phone", $$v)},expression:"modelData.phone"}}):_c('van-field',{attrs:{"required":"","input-align":"right","error-message-align":"right","label":"手机号","rules":[
                    {required: true, message: '请输入手机号',trigger: 'onBlur' },
                    { validator:_vm.validatorPhone, message: '请输入正确手机号',trigger: 'onBlur' },   
                ],"placeholder":"请填写正确的手机号以保证预约顺畅"},model:{value:(_vm.modelData.phone),callback:function ($$v) {_vm.$set(_vm.modelData, "phone", $$v)},expression:"modelData.phone"}}),_c('van-field',{attrs:{"required":"","label":"证件类型","readonly":true,"input-align":"right","value":_vm.modelData.cardTypeStr,"is-link":"","placeholder":"请选择证件类型","error-message-align":"right","rules":[
                    {required: true, message: '请选择证件类型',trigger: 'onChange' },
                ]},on:{"click":function($event){_vm.showIdtypePicker = true}}}),_c('van-popup',{staticClass:"IdtypePicker",style:({ height: '45%' }),attrs:{"position":"bottom"},model:{value:(_vm.showIdtypePicker),callback:function ($$v) {_vm.showIdtypePicker=$$v},expression:"showIdtypePicker"}},[_c('van-picker',{attrs:{"value-key":"description","show-toolbar":"","columns":_vm.idTypeList},on:{"confirm":_vm.onIdTypeConfirm,"cancel":function($event){_vm.showIdtypePicker = false}}})],1),_c('van-field',{attrs:{"required":"","error-message-align":"right","input-align":"right","rules":[
                    {required: true, message: '请输入证件号码',trigger: 'onBlur' },
                    {validator:_vm.validatorCardNo, message: '请输入正确证件号码',trigger: 'onBlur' },
                ],"label":"证件号码","placeholder":"请填写正确的证件号码以保证预约顺畅"},model:{value:(_vm.modelData.cardNo),callback:function ($$v) {_vm.$set(_vm.modelData, "cardNo", $$v)},expression:"modelData.cardNo"}}),(_vm.modelData.cardType !== 1)?_c('van-field',{attrs:{"required":"","label":"出生日期","input-align":"right","value":_vm.modelData.birthday,"is-link":"","readonly":true,"error-message-align":"right","placeholder":"请选择出生日期","rules":[
                    {required: true, message: '请选择出生日期',trigger: 'onChange' },
                ]},on:{"click":function($event){_vm.showDate = true}}}):_vm._e(),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showDate),callback:function ($$v) {_vm.showDate=$$v},expression:"showDate"}},[_c('van-datetime-picker',{attrs:{"type":"date","title":"选择年月日","minDate":_vm.minDate,"maxDate":_vm.maxDate},on:{"confirm":_vm.onDateConfirm,"cancel":_vm.onDateCancel},model:{value:(_vm.birthdayDate),callback:function ($$v) {_vm.birthdayDate=$$v},expression:"birthdayDate"}})],1),(_vm.modelData.cardType !== 1)?_c('van-field',{attrs:{"required":"","label":"性别","readonly":true,"input-align":"right","value":_vm.modelData.sexStr,"is-link":"","placeholder":"请选择性别","error-message-align":"right","rules":[
                    {required: true, message: '请选择性别',trigger: 'onChange' },
                ]},on:{"click":function($event){_vm.showSexPicker = true}}}):_vm._e(),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showSexPicker),callback:function ($$v) {_vm.showSexPicker=$$v},expression:"showSexPicker"}},[_c('van-picker',{attrs:{"value-key":"name","show-toolbar":"","columns":_vm.sexList},on:{"confirm":_vm.onSexConfirm,"cancel":function($event){_vm.showSexPicker = false}}})],1),_c('van-field',{attrs:{"required":"","label":"婚否","readonly":true,"input-align":"right","value":_vm.modelData.marriageStatusStr,"is-link":"","placeholder":"请选择婚否","error-message-align":"right","rules":[
                    {required: true, message: '请选择婚否',trigger: 'onChange' },
                ]},on:{"click":function($event){_vm.showMarriagePicker = true}}}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showMarriagePicker),callback:function ($$v) {_vm.showMarriagePicker=$$v},expression:"showMarriagePicker"}},[_c('van-picker',{attrs:{"value-key":"name","show-toolbar":"","columns":_vm.marriageList},on:{"confirm":_vm.onMarriageConfirm,"cancel":function($event){_vm.showMarriagePicker = false}}})],1),(!_vm.isSelf)?_c('van-field',{staticClass:"mt8",attrs:{"error-message-align":"right","rules":[
                    {required: true, message: '请选择与本人关系',trigger: 'onChange' },
                ],"input-align":"right","required":"","label":"与本人的关系","readonly":true,"placeholder":"请选择","value":_vm.modelData.familyTypeStr,"is-link":""},on:{"click":_vm.onShowRelation}}):_vm._e(),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showRelationPicker),callback:function ($$v) {_vm.showRelationPicker=$$v},expression:"showRelationPicker"}},[_c('van-picker',{attrs:{"value-key":"description","show-toolbar":"","columns":_vm.relationshipList},on:{"confirm":_vm.onRelationConfirm,"cancel":function($event){_vm.showRelationPicker = false}}})],1),_c('van-field',{staticClass:"mt8 mb8",attrs:{"input-align":"right","type":"textarea","label":"备注","placeholder":"请输入备注信息"},model:{value:(_vm.modelData.remark),callback:function ($$v) {_vm.$set(_vm.modelData, "remark", $$v)},expression:"modelData.remark"}}),(_vm.modelData.canUpdate && _vm.dataSource.button)?[_c('div',{staticClass:"contacts-submit-btn"},[_c('button',{staticClass:"btn btn-block",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.dataSource.button.buttonText))])])]:_vm._e()],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }